// libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Utils
import { formatNumber } from '../../utils/utils';

// utils components
import Image from '../../utils/components/Image';

function DangerStatus({ status }) {
  switch (status) {
    case 'dz':
      return <span className="status dz"> Danger zone </span>;
    case 'wz':
      return <span className="status sz"> July 13, 2020 </span>;
    case 'sz':
      return <span className="status wz"> July 14, 2020 </span>;
    case 'intern':
      return <span className="status wzz"> June 2020 </span>;
    case 'sttp1':
      return <span className="status sz"> Dec 2020 </span>;
    case 'sttp2':
      return <span className="status wz"> Jan 2021 </span>;
    case 'fopos':
      return <span className="status wzz"> Feb 02, 2021 </span>;

    default:
      return null;
  }
}

function WebinarCard({
  id,
  image,
  category,
  name,
}) {
  return (
    <div className="webinar-card">
      <div className="top-bar">
        <div
          style={{
            maxWidth: '250px',
            minHeight: '100px',
            textAlign: 'left'
          }}
          className="name"
        >
          <h2>{name}</h2>
          <small>{category}</small>
        </div>
      </div>
      <div  style={{
            maxWidth: '240px',
            minHeight: '160px'
          }} className="image">
          <Image src={image} />
        </div>
      <div className="details">

        <Link to={`/viz/id/${id}`} className="btn-primary small round ">
          See more <i className="right fa fa-arrow-right" />{' '}
        </Link>
      </div>
    </div>
  );
}

export default WebinarCard;
