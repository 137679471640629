// libraries
import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// react hooks
import { GlobalContext } from '../../state-manager/globalState';
import webinarImageFile from '../../public/webinars.jpg';
import ProfSahu from '../../public/ProfSahu.jpg';
import MrAmey from '../../public/MrAmey.jpg';

import logoKJ from '../../public/logoKJCropped.png';
import aicte from '../../public/aicte.png';
import mu from '../../public/mu.png';
import svv from '../../public/svv.png';
import intersnhip from '../../public/checklist1.png';
import GoogleAd from '../GoogleAd';
// components
import WebinarCard from './WebinarCard';

function Webinars () {
  // const { countries } = useContext(GlobalContext);


  const [filtredCountries, setFiltredCountries] = useState();
  // const data = countries.data || filtredCountries.data;
  const [data, setData] = useState()
  const [alldata, setAllData] = useState()
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getResult = async () => {
      fetch('https://dsa-main.vercel.app/data.json')
        .then(response => response.json())
        .then((jsonData) => {
          console.log(jsonData)
          setData(jsonData.data)
          setAllData(jsonData.data)
          setIsLoading(false);

          // jsonData is parsed json object received from url
          console.log(jsonData)
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })

    };
    getResult();
  }, []);

  function handleChangeSearchKey (search_key) {
    
    console.log(search_key.target.value)
    if (!search_key.target.value.includes("  ")) {
      // setSearchKey(search_key.target.value);

      if (search_key.target.value) {
        // split the words
        const search_keys = search_key.target.value.split(" ");
        const newData = alldata.filter((country) => {
          const country_name = country.name.toLowerCase();
          for (let i = 0; i < search_keys.length; i++) {
            if (!country_name.includes(search_keys[i].toLowerCase())) {
              return false;
            }
          }
          return true;
        });
        setData(newData);
      } else {
        setData(alldata);
      }
    }
  }

  return (
    <div className="webinars">
      <Helmet>
        <title>
          {`DSA Learning tool`}
        </title>
      </Helmet>
      <h1 style={{ textAlign: 'center', color: '#020266' }} className="info">
        Data Structures' Visualization Tool
      </h1>
      <h2 style={{ textAlign: 'center', color: '#020266' }} className="info">
        Understanding through Animation
      </h2>
      <div className="search">
      <input
                    type="text"
                    className="search-box"
                    placeholder="Search..."
                    onChange={handleChangeSearchKey}
        />
        </div>
      {!isLoading &&
        <div className="container">
          {data.map(webinar => (
            <WebinarCard key={webinar.id} {...webinar} />
          ))}
        </div>}
    </div>
  );
}

export default Webinars;
