
const DEBUG = false

export const WEB_HOST = DEBUG ? 'localhost:3000/':'/';

export const WEB_API_HOST = `${WEB_HOST}api/`;





export const API_URLS = {
	countries: `${WEB_API_HOST}`, // no params
	country_predictions: `${WEB_API_HOST}`,  // id
};

export const homeImage = `${WEB_HOST}static/static/media/homeImage.87ae5d21.png`;
export const resourceImage = `${WEB_HOST}static/static/media/resourcesImage.9535de54.png`;






export const googleTrackingId = "UA-172747167-1";

export const DAYS_NUMBER = 92;