// libraries
import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import Iframe from 'react-iframe'
import { Link, useParams, Redirect } from 'react-router-dom';

import axios from 'axios';

function Viz () {
  const { id } = useParams();
  const [html, setHtml] = useState('<html> </html>');
  const [isLoading, setIsLoading] = useState(true);
  const [webinar, setwebinar] = useState(null);

  const loaded = async () =>{
    setIsLoading(false);
  }

  useEffect(() => {
    const getResult = async () => {

      fetch('https://dsa-main.vercel.app/viz.json')
        .then(response => response.json())
        .then((jsonData) => {
          console.log(jsonData)
          const vixD = jsonData
          setwebinar(vixD[id]);
         
          // jsonData is parsed json object received from url

        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
    };
    getResult();
  }, []);

  return (
    <>
      {webinar &&
        <Helmet>
          <title>
            {webinar.name}
          </title>
        </Helmet>
      }

{isLoading && 
      <main>
      {' '}
      <div className="loading">Loading</div>{' '}
    </main>}
      {webinar  &&
        <div class="iframe-wrapper">
          <Iframe url={webinar.url}
          width="1050px"
          onLoad={loaded}
            id="myId"
            className="myClassname"
            display="initial"
            position="relative" />
        </div>
      }
    </>
  );
}

export default Viz;
